.mm {

	&-menu {
		z-index: 2;
		top: 77px;

		--mm-lineheight: #{$global-control-height};
		--mm-listitem-size: #{$global-control-large-height};
		--mm-navbar-size: #{$global-control-height};
		--mm-toggle-size: #{$global-control-small-height};
		--mm-translate-multiplier: 0;

		&[inert='true'] {
			pointer-events: none;
			cursor: default;
		}

		&[inert='true'],
		&[inert='true'] * {
			user-select: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}

		&--theme-light {
			--mm-size: 100%;
			--mm-min-size: 256px;
			--mm-max-size: 2048px;
			--mm-color-background: #{$black};
			--mm-color-border: #{rgba($white, 0.05)};
			--mm-color-icon: #{$white};
			--mm-color-text: #{$white};
			--mm-color-text-dimmed: #{rgba($white, 0.5)};
			--mm-color-background-highlight: #{rgba($white, 0.05)};
			--mm-color-background-emphasis: #{rgba($global-muted-background, 0.4)};
			--mm-color-focusring: #{$global-color};
		}

		.social {
			padding: $global-small-margin;
		}

		@media (max-width: $breakpoint-mobile) {
			top: 74px;
		}
	}

	&-listitem {

		&::after {
			left: 0;
		}

		&:last-child::after {
			display: none;
		}
	}

	&-wrapper {

		&--opened {

			.mm-page {
				max-height: 100vh;
				overflow: hidden;
			}

			.header {

				a[href='#mmenu'] {
					display: none !important;
				}

				a[href='#page'] {
					display: flex !important;
				}
			}
		}

		&__blocker {
			background: 0 0;
			opacity: 1;
			z-index: 1;
		}
	}

	&-panels>div:first-child>.mm-navbar {
		display: none;
	}
}